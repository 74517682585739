module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NBLGSFX","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"function(){return{event:\"Pageview\",loginStatus:\"out\",pageArea:\"pub\",pageItem:\"free trial and join now\",pageSection:\"new info page\",experience:\"v2\",pageType:\"infopage\"}}"},"gtmAuth":"2HS4qFNTwi_iVjFt8c5mWA","gtmPreview":"env-2","dataLayerName":"dataLayer","enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-VHKGTDRGNH","UA-37339465-1"]},
    },{
      plugin: require('../node_modules/@crometrics/gatsby-plugin-optimizely-js/gatsby-browser.js'),
      options: {"plugins":[],"optimizelyId":"11492672549"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Blink Fitness","description":"Blink offers an affordable gym membership with tons of gym equipment, certified personal training programs, and a free 30-minute start-up session.","start_url":"/","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a16feddf8069dcecd972229a439a71d1"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
