import { normalize } from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';
import { media } from './breakpoints';
import './fonts.scss';

import BlinkFitness_Balanced from '../fonts/BlinkFitness_Balanced.ttf';
import BlinkFitness_Lean from '../fonts/BlinkFitness_Lean.ttf';
import BlinkFitness_Strong from '../fonts/BlinkFitness_Strong.ttf';

export const colors = {
  orange: '#FF5100',
  lightOrange: '#FF853C',
  red: '#C30F00',
  blue: '#095CD3',
  offBlue: '#6088FF',
  darkBlue: '#4A4F54',
  charcoal: '#4A4F54',
  gray: '#B3B3B3',
  lightGray: '#F6F6F6',
  midGray: '#e5e5e5',
  darkGray: '#445361',
} as const;

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  @font-face {
    font-family: BlinkFitness_Lean;
    src: url('${BlinkFitness_Lean}') format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: BlinkFitness_Balanced;
    src: url('${BlinkFitness_Balanced}') format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: BlinkFitness_Strong;
    src: url('${BlinkFitness_Strong}') format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  #gatsby-focus-wrapper{
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    > #content{
      flex-grow: 1;
    }
  }

  body {
    margin: 0;
    font-family: BlinkFitness_Lean,Helvetica Neue,Arial,sans-serif;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    background-color: #fff;
    font-size: 18px;
    line-height: 27px;
    color: ${colors.charcoal};
    background: white;
    font-size: 16px;
    line-height: 24px;

    ${media.tablet`
      font-size: 18px;
      line-height: 27px;
    `}
  }

  a{
    text-decoration: none;
    color: ${colors.blue};

    &:hover,
    &:focus{
      color: ${colors.darkGray};
    }
  }

  button{
    cursor: pointer;
    color: inherit; //This is important for iOS
    padding: 6px; //This differs between browsers
  }

  h1,.h1 {
    font-family: BlinkFitness_Strong,Helvetica Neue,Arial,sans-serif;
    font-weight: 700;
    text-transform: lowercase;
    margin: 0 0 24px;
    font-size: 48px;
    line-height: 54px;

    ${media.tablet`
      font-size: 64px;
      line-height: 70px;
    `}
  }

  h2,.h2 {
    font-family: BlinkFitness_Strong,Helvetica Neue,Arial,sans-serif;
    font-weight: 700;
    text-transform: lowercase;
    margin: 0 0 24px;
    font-size: 40px;
    line-height: 40px;

    ${media.tablet`
      font-size: 58px;
      line-height: 60px;
    `}
  }

  h3,.h3 {
    font-family: BlinkFitness_Strong,Helvetica Neue,Arial,sans-serif;
    font-weight: 700;
    text-transform: lowercase;
    font-size: 32px;
    margin: 0 0 24px;
    line-height: 31px;
    font-size: 23px;

    ${media.desktop`
      font-size: 32px;
      line-height: 37px;
    `}
  }

  p {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  img{
    max-width: 100%;
  }

  hr {
    border: none;
    height: 2px;
    background: #eaeaea;

    margin: 48px 0;
  }

  svg{
    //iOS Safari tends to shrink SVG on us while other browsers don't
    flex-shrink: 0;
  }
`;
