import React from 'react';
import ButterBar from './ButterBar';

export default function OldBrowserButterBar() {
  const [isIE, setIsIE] = React.useState(false);
  React.useEffect(() => {
    const msie = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
    setIsIE(msie);
  }, []);

  return isIE ? <ButterBar message="The browser or version you are using is unsupported. If you experience issues, please try another browser or update to the latest version. " /> : <></>;
}
