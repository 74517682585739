import React from 'react';
import styled from 'styled-components';
import Blink from '../../images/blink.inline.svg';
import { media } from '../../includes/breakpoints';
import { colors } from '../../includes/style';
import Button from '../utils/Button';
import Container from './Container';

const JoinLink = styled(Button).attrs((props)=>{
  return {
    variant: 'blue',
    size: 'small'
  };
})`
  > .extra{
    display: none;

    ${media.large`
      display: inline;
    `}
  }
`;

const NavigationStyle = styled.nav`
  background: rgba(255, 255, 255, 0.94);
  box-shadow: 0px 1px 0px rgba(74, 79, 84, 0.1);
  backdrop-filter: blur(30px);

  ${Container}{
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${media.large`
      min-height: 100px;
    `}
  }
`;

export default function Header(){
  return <NavigationStyle id="navigation" className="navbar">
    <Container>
      <a href="https://www.blinkfitness.com/"><Blink /></a>
      <JoinLink href="https://locations.blinkfitness.com/" aria-label="Sign up for BlinkFitness - Join Now">Join<span className="extra"> Now</span></JoinLink>
    </Container>
  </NavigationStyle>;
}
