import React from 'react';
import { isBrowser } from '../../includes/analytics';

export type StoreFormData = {
  firstName?: string,
  lastName?: string,
  email?: string,
  phone?: string,
  state?: string,
  area?: string,
  gym?: string,
};

type GymAddressData = {
  city?: string,
  countryCode?: string
  line1?: string,
  postalCode?: string,
  region?: string,
};

type GymHoursData = {
  [day: string]: {
    openIntervals: {
      start:string,
      end: string
    }
  }
};

export type GymData = {
  address?: GymAddressData,
  hours?: GymHoursData,
  phone?: string
  id?: string,
  slug?: string,
  barcode?: string,
  clubType?: string,
  name?: string
};

const defaultFormData = isBrowser && JSON.parse(sessionStorage.getItem('formData') || '{"firstName": "", "lastName": "", "email": "", "phone": "", "state": "", "area": "", "gym": ""}');

type StoreContextValue = {
  formData: StoreFormData,
  updateFormData: (data: Partial<StoreFormData>)=>Partial<StoreFormData>,
  updateFormField: (key:string, value:string)=>void,
  resetFormData: ()=>void,
  gymData: GymData,
  setGymData: (status:any)=>void,
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const storeContext = React.createContext({} as StoreContextValue);

/* This is from https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/ */
export default function StoreProvider({ children }: { children: React.ReactNode; }) {
  const [ formData, setFormData ] = React.useState<StoreFormData>(defaultFormData);
  const [ gymData, setGymData] = React.useState<GymData>({});

  const persistFormData = (formData: Partial<StoreFormData>)=>{
    setFormData(formData);
    sessionStorage.setItem('formData', JSON.stringify(formData));
  };

  const updateFormData = (newFormData: Partial<StoreFormData>)=>{
    const mergedFormData = {
      ...formData,
      ...newFormData,
    };
    persistFormData(mergedFormData);
    return mergedFormData;
  };

  const updateFormField = (key, value) => {
    formData[key] = value;
    persistFormData(formData);
  };

  const resetFormData = ()=>{
    persistFormData({});
  };

  return (
    <storeContext.Provider value={{
      formData,
      gymData,
      setGymData,
      updateFormData,
      updateFormField,
      resetFormData,
    }}>
      {children}
    </storeContext.Provider>
  );
}

export const useGlobalStore = () => {
  return React.useContext(storeContext);
};
