import React, { ComponentType } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../includes/style';

type ButtonVariants = 'blue';
type ButtonLengths = 'full';
type ButtonSizes = 'small';

type ButtonProps = {
  as?: string | ComponentType<any> | undefined,
  href?: string,
  variant?: ButtonVariants,
  length?: ButtonLengths,
  size?: ButtonSizes
};

const ButtonStyle = styled.button<ButtonProps>`
  font-family: BlinkFitness_Strong,Helvetica Neue,Arial,sans-serif;
  display: inline-block;
  border-radius: 1000px;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 32px;
  text-decoration: none;
  transition: color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 2px 0px;
  text-align: center;
  border: 2px solid ${colors.orange};
  background: ${colors.orange};
  color: white;
  cursor: pointer;
  line-height: 1;

  &:hover {
    background: none;
    color: ${colors.orange};
  }

  ${props=>props.variant === 'blue'?css`
    border: 2px solid ${colors.blue};
    background: ${colors.blue};
    color: white;

    &:hover {
      background: white;
      color: ${colors.blue};
    }
  `:''}

  ${props=>props.length === 'full'?css`
    width: 100%;
  `:''}

  ${props=>props.size === 'small'?css`
    font-size: 14px;
    padding: 10px 30px;
  `:''}
`;

export default function Button(props:ButtonProps&(React.ButtonHTMLAttributes<HTMLButtonElement>|React.AnchorHTMLAttributes<HTMLAnchorElement>)){
  const buttonProps = props.href ? { ...props, as: 'a' } : props;
  return <ButtonStyle { ... buttonProps } data-variant={props.variant} data-length={props.length} data-size={props.size}>{props.children}</ButtonStyle>;
}
