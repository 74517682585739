import React from 'react';
import styled from 'styled-components';
import { media } from '../../includes/breakpoints';
import { colors } from '../../includes/style';
import Container from './Container';
import Blink from '../../images/blink.inline.svg';

const FooterStyle = styled.footer`
  font-size: 14px;
  line-height: 1.25em;

  ${Container}{
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .footer__disclaimer {
    font-size: 13px;
  }
`;

const FooterTop = styled.div`
  display: flex;
  align-items:flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 16px;

  ${media.tablet`
    padding-bottom: 0;
    flex-direction: row;
  `}
`;

const Copyright = styled.small`
  color: ${colors.gray};
  font-size: 14px;
  padding-bottom: 32px;
  display: block;
`;

const Links = styled.div`
  flex-direction: column;
  display: flex;
  padding-top: 12px;

  ${media.tablet`
    padding-top: 0;
  `}

  a {
    color: ${colors.charcoal};
    font-family: BlinkFitness_Balanced;
    padding-bottom: 12px;
    font-size: 14px;

    ${media.tablet`
      padding-bottom: 24px;
      font-size: 16px;
    `}

  }

  a:hover {
    color: ${colors.orange};
  }


  .footer__legal {
    color: ${colors.gray};
    font-size: 12px;
    text-transform: lowercase;
    padding-bottom: 12px;
    ${media.tablet`
      padding-bottom: 24px;
    `}
  }
`;

//     <footer id="footer">
//       <div className="container">
//         © {new Date().getFullYear()}, Built with{` `}<a href="https://www.gatsbyjs.com">Gatsby</a>
//       </div>
//     </footer>

export default function Footer(){
  return (
    <FooterStyle id="footer">
      <Container>
        <FooterTop className="footer-top">
          <Blink />
          <Links>
            <span className="footer__legal">Legal</span>
            <a href="https://www.blinkfitness.com/privacy">Privacy Policy</a>
            <a href="https://www.blinkfitness.com/terms">Terms Of Use</a>
          </Links>
        </FooterTop>

        <Copyright>© 2022 Blink Fitness. All rights reserved.</Copyright>
        <p className="footer__disclaimer">Blink Fitness is the gym that puts Mood above Muscle. We have all the state-of-the-art equipment that other gyms offer – including gear for resistance training, cardio workouts, functional training and a multi-use stretch area. Our strength training areas are perfect for everything from weight training for beginners to bodybuilding exercises for experienced exercisers, and for specific workouts like back strengthening exercises or ankle strengthening exercises. The Blink app offers 500+ on-demand workout videos and classes that let you do fitness on the go. And your membership includes a free 30-minute personal training session where you can start to build a personalized workout plan. Our trainers know how to keep fitness fun while supplying the workout motivation you need to reach your goals. But the thing that truly sets us apart is our body-positive culture. Blink is a place where everyone feels comfortable working out, and where getting fit is a mood-lifting experience.</p>
      </Container>
    </FooterStyle>
  );
}
